.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  font-size: 12px;
  color: var(--primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  &:focus {
    filter: brightness(0.95);
  }
}

.error {
  width: 80%;
  border-radius: 20px;
  border: var(--border-in-light);
  box-shadow: var(--card-shadow);
  padding: 20px;
  overflow: auto;
  background-color: var(--white);
  color: var(--black);

  pre {
    overflow: auto;
  }
}

.selectable {
  border: 2px solid transparent;
  cursor: pointer;

  &.hover {
    background-color: var(--hover-color);
  }

  &:hover {
    .hover();
  }

  &.selected {
    border-color: var(--primary);
  }
}

.disabled {
  pointer-events: none;
}

.hide {
  display: none !important;
}
