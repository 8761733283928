@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.common {
  --timing-function: cubic-bezier(0, 0.9, 0, 1);
  --animation-time: 0.3s;
  --common-transition: all var(--animation-time) var(--timing-function);
  --appear-animation: appear var(--animation-time) var(--timing-function) both;
}

.light {
  --theme: light;

  /* color */
  --white: white;
  --black: rgb(33, 33, 33);
  --gray: rgb(250, 250, 250);
  --primary: rgb(29, 147, 171);
  --second: rgb(231, 248, 255);
  --hover-color: #f3f3f3;
  --bar-color: rgba(0, 0, 0, 0.1);
  --theme-color: var(--gray);

  /* shadow */
  --shadow: 50px 50px 100px 10px rgba(0, 0, 0, 10%);
  --card-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 5%);

  /* stroke */
  --border-in-light: 1px solid rgb(222, 222, 222);
}

.dark {
  --theme: dark;

  /* color */
  --white: rgb(33, 33, 33);
  --black: rgb(187, 187, 187);
  --gray: rgb(21, 21, 21);
  --primary: rgb(29, 147, 171);
  --second: rgb(27 38 42);
  --hover-color: #323232;

  --bar-color: rgba(255, 255, 255, 0.1);

  --border-in-light: 1px solid rgba(255, 255, 255, 0.192);

  --theme-color: var(--gray);

  div:not(.no-dark) > svg {
    filter: invert(0.5);
  }
}

.pc {
  --sidebar-width: 350px;
  --window-content-width: calc(100% - var(--sidebar-width));
  --message-max-width: 80%;
  --message-input-width: calc(100% - 4ch);
}

.mobile {
  --sidebar-width: 100vw;
  --window-content-width: 100%;
  --message-max-width: 100%;
  --message-input-width: calc(100% - 4ch);
}

:root {
  .common();
  .light();
  .pc();

  @media only screen and (max-width: 768px) {
    .mobile();
  }

  @media (prefers-color-scheme: dark) {
    .dark();
  }
}

.light-mode {
  .light();
}

.dark-mode {
  .dark();
}
