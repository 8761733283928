.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1ch;

  &.full {
    width: 100%;
    height: 100%;
  }

  &.min {
    width: fit-content;
    height: fit-content;
  }

  &.remaining {
    flex: 999;
  }
}
